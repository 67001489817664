import React, { useMemo, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { useResponsive } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { SidebarNavigationItem, sidebarNavigation } from '../../sidebar-navigation'
import * as S from './styles'
import { ItemType, MenuItemType } from 'antd/lib/menu/hooks/useItems'

interface SliderContentProps {
  setCollapsed: (isCollapsed: boolean) => void
  isCollapsed?: boolean
}

const SliderMenu: React.FC<SliderContentProps> = ({
  setCollapsed,
  isCollapsed,
}) => {
  const [activeId, setActiveId] = useState<string>('dashboard')
  const { verifyPermission } = useVerifyPermission()
  const { t } = useTranslation()
  const { mobileOnly } = useResponsive()
  const location = useLocation()

  useEffect(() => {
    const sections = document.querySelectorAll('div[id]')
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id)
          }
        })
      },
      { rootMargin: '-50% 0px -50% 0px' }
    )

    sections.forEach((section) => observer.observe(section))

    return () => {
      sections.forEach((section) => observer.unobserve(section))
    }
  }, [])

  const listSidebarNavigationWithPermission = useMemo(() => {
    return sidebarNavigation.filter(nav => {
      if (!nav.permission) {
        return true
      }
      return verifyPermission(nav.permission)
    })
  }, [sidebarNavigation, verifyPermission])

  const defaultSelectedKeys = activeId ? [activeId] : []
  const openedSubmenu = listSidebarNavigationWithPermission.find(
    ({ children }) => children?.some(({ url }) => url === location.pathname),
  )
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : []

  const renderLabel = ({
    key,
    nav,
    isSubMenu,
  }: {
    key: string
    isSubMenu?: boolean
    nav: SidebarNavigationItem
  }) => {
    const isSelected = key === defaultSelectedKeys[0]
    const stylesOverwrite = isCollapsed ? { height: '30px' } : {}
    if (isCollapsed && isSubMenu) {
      return (
        <S.ItemMenuCustomWrapper
          align={'middle'}
          $isSelected={isSelected}
          style={stylesOverwrite}
        >
          <S.LeftPoint $isSelected={isSelected} />
          {isSelected ? nav?.iconFocus : nav?.icon}
        </S.ItemMenuCustomWrapper>
      )
    }
    if (isCollapsed && !isSubMenu) {
      return (
        <a href={`#${nav.key}`}>
          <S.ItemMenuCustomWrapper
            align={'middle'}
            $isSelected={isSelected}
            style={stylesOverwrite}
          >
            <S.LeftPoint $isSelected={isSelected} />
            {isSelected ? nav?.iconFocus : nav?.icon}
          </S.ItemMenuCustomWrapper>
        </a>
      )
    }
    return (
      <S.ItemMenuCustomWrapper
        align={'middle'}
        $isSelected={isSelected}
        style={stylesOverwrite}
      >
        <S.LeftPoint $isSelected={isSelected} />
        {isSelected ? nav?.iconFocus : nav?.icon}
        <S.LabelCustom $isSelected={isSelected}>
          {isSubMenu ? (
            t(nav.title)
          ) : (
            <a href={`#${nav.key}`}>{t(nav.title)}</a>
          )}
        </S.LabelCustom>
      </S.ItemMenuCustomWrapper>
    )
  }

  const onClickMenuItem = () => {
    if (mobileOnly && isCollapsed === true) {
      setCollapsed(true)
    }
  }

  return (
    <S.Menu
      mode="horizontal"
      selectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={onClickMenuItem}
      items={listSidebarNavigationWithPermission.map(nav => {
        const isSubMenu = !!nav.children?.length
        return {
          key: nav.key,
          title: t(nav.title),
          label: renderLabel({ key: nav.key, isSubMenu, nav }),
          children:
            isSubMenu &&
            nav.children?.map(childNav => ({
              key: childNav.key,
              title: t(childNav.title),
            })),
        } as ItemType<MenuItemType>
      })}
    />
  )
}

export default SliderMenu
