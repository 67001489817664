import { memo } from 'react'
import { isEqual } from 'lodash'
import MobileDashboardContainer from 'app/containers/DashboardMobile'

const DashboardCpn = () => {
  return (
    <>
      <MobileDashboardContainer />
    </>
  )
}

export const MobileDashboardPage = memo(DashboardCpn, isEqual)
