import { PermissionEnum } from 'app/common/enum'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { useAppSelector } from 'app/redux/hooks'
import { useNavigate } from 'react-router'

export const useVerifyPermission = () => {
  const navigate = useNavigate()
  const user = useAppSelector(state => state?.user)?.profile

  const verifyPermission = (permission: PermissionEnum) => {
    // const permissions = user?.roles
    //   ?.map(role => role?.permissions)
    //   .flat()
    //   ?.map(permission => permission?.permission)

    // console.log({ permissions, permission })

    // // check if user has permission
    // if (permissions?.includes(permission)) {
    //   return true
    // }

    return false
  }

  const verifyPermissionAndRedirect = (permission: PermissionEnum) => {
    const res = verifyPermission(permission)

    if (!res) {
      navigate(DASHBOARD_PATH)
    }
  }

  return { verifyPermission, verifyPermissionAndRedirect, user }
}
