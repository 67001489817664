import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import MobileProjectsContainer from 'app/containers/ProjectsMobile'

const ProjectsCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.project)}</PageTitle>
      <MobileProjectsContainer />
    </>
  )
}
export const MobileProjectsPage = memo(ProjectsCpn, isEqual)
