import React from 'react';
import * as S from './styles';

interface AdminContentProps {
  $isTwoColumnsLayout: boolean;
  children?: React.ReactNode;
}

export const AdminContent: React.FC<AdminContentProps> = ({ $isTwoColumnsLayout, children }) => {
  return (
    <S.ContentWrapper $isTwoColumnsLayout={$isTwoColumnsLayout}>
      {children}
    </S.ContentWrapper>
  );
};
