export default {
  yes: 'Có',
  no: 'Không',
  fill_barcode: 'Nhập mã',
  see_more: 'Xem thêm',
  choose: 'Chọn',
  find: 'Tìm kiếm',
  address: '90/4 Đường số 2, Phường Phú Mỹ, Quận 7',
  language: 'Ngôn ngữ',
  vietnamese: 'Tiếng việt',
  english: 'English',
  foreign_language: 'Ngoại ngữ',
  home: 'Home',
  phone: 'Số điện thoại',
  email: 'Email',
  send: 'Gửi',
  next: 'Tiếp theo',
  back: 'Quay lại',
  minute: 'phút',
  hour: 'giờ',
  send_code: 'Gửi mã',
  login: 'Đăng nhập',
  user_name: 'Tên đăng nhập',
  password: 'Mật khẩu',
  re_password: 'Nhập lại mật khẩu',
  re_password_not_match: 'Mật khẩu không trùng khớp',
  change_password: 'Đổi mật khẩu',
  change_password_success: 'Đổi mật khẩu thành công',
  login_success: 'Đăng nhập thành công',
  forgot_password: 'Quên mật khẩu',
  require_field: 'Vui lòng nhập "{{field}}", đây là trường bắt buộc',
  required: 'Bắt buộc',
  minimum_field: 'Vui lòng nhập tối thiểu {{count}} kí tự',
  maximum_field: 'Vui lòng nhập tối đa {{count}} kí tự',
  register: 'Đăng kí',
  confirm_password: 'Xác nhận mật khẩu',
  verify_by_phone: 'Xác thực bằng số điện thoại',
  verify_by_email: 'Xác thực bằng số email',
  code_verify: 'Mã xác thực',
  message_verify_phone:
    'Bạn vui lòng kiểm tra trong tin nhắn Zalo của số điện thoại {{phone}} để lấy mã xác thực.',
  message_verify_email:
    'Bạn vui lòng kiểm tra trong hộp thư (bao gồm cả thư rác) của email "{{email}}" để lấy mã xác thực.',
  re_send_verify_code: 'Gửi lại mã xác thực',
  resend: 'Gửi lại',
  time_resend_verify_code: 'Gửi lại sau {{timer}} giây',
  verify: 'Xác thực',
  logout: 'Đăng xuất',
  description: 'Mô tả',
  full_name: 'Họ và tên',
  regex_name: 'Tên cần đúng định dạng',
  regex_user_name: 'User name cần đúng định dạng (Không bao gồm dấu "cách")',
  using_by: 'Được sử dụng bởi',
  require_email: 'Vui lòng nhập email',
  require_contact_name: 'Vui lòng nhập tên người liên hệ',
  regex_email: 'Vui lòng nhập đúng định dạng email',
  regex_phone: 'Vui lòng nhập đúng định dạng số điện thoại',
  send_contact_success:
    'Đã gửi thông tin liên hệ thành công, cảm ơn quý khách.',
  please_enter_correct_format: 'Vui lòng  định dạng',
  dashboard: 'Trang chủ',
  development: 'Đang phát triển',
  profile: 'Hồ sơ cá nhân',
  week: 'Tuần',
  choose_week: 'Chọn tuần',
  degree_content_placeholder_upload_document: 'Tải lên tài liệu',
  upload: 'Tải lên',
  cancel: 'Hủy',
  confirm: 'Xác nhận',
  not_exist_page: 'Trang không tồn tại',
  search: 'Tìm kiếm',
  export_excel: 'Export Excel',
  read_all: 'Đọc tất cả',
  view_all: 'Xem tất cả',
  download_example_import_file: 'Tải file mẫu import',
  import: 'Import',
  import_file: 'Import file',
  introduce: 'Giới thiệu',
  services: 'Dịch vụ',
  project: 'Dự án',
  contact: 'Liên hệ',
  company_info: 'Thông tin công ty',
  team_management: 'Đội ngũ',
  development_history: 'Lịch sử phát triển',
  new_section: 'Mục mới',
  new_content: 'Nội dung mới',
  save_all: 'Lưu tất cả',
  edit: 'Chỉnh sửa',
  add_new_section: 'Thêm mục mới',
  delete: 'Xóa',
  upload_image: 'Tải lên hình ảnh',
  content: 'Nội dung',
  title: 'Tiêu đề',
  subtitle: 'Phụ đề',
  service_list: 'Danh sách dịch vụ',
  content_services: 'Nội dung dịch vụ',
  project_list: 'Danh sách dự án',
  project_content: 'Nội dung dự án',
  pictures_and_videos: 'Hình ảnh và video thực tế',
  contact_via_zalo: 'Liên hệ qua zalo',
  contact_via_phone: 'Liên hệ qua điện thoại',
  add_new_contact: 'Thêm liên hệ mới',
  enter_email_or_phone: 'Nhập email hoặc số điện thoại',
  remember_me: 'Nhớ lại',
  admin: 'Quản trị viên',
  team: 'Đội ngũ',
  partner: 'Đối tác',
  enter_email: 'Nhập email',
  reset_password: 'Đặt lại mật khẩu',
  enter_verification_code: 'Nhập mã xác nhận',
  enter_new_password: 'Nhập mật khẩu mới',
  new_password: 'Mật khẩu mới',
  avatar: 'Hình đại diện',
  news_management: ' Quản lý tin tức',
  task: 'Tác vụ',
  companyName: 'CÔNG TY TNHH TM VẬN TẢI DU LỊCH LÊ VY',
  taxCode: 'MST: 0315736498',
  headquarters:
    'TRỤ SỞ: 766/3 Tỉnh Lộ 10, KP18, Phường Bình Trị Đông, Quận Bình Tân',
  office: 'VĂN PHÒNG: 90/4 Đường số 2, Phường Phú Mỹ, Quận 7',
  hotline: 'HOTLINE: (+84) 91 891 3838',
  emaillv: 'EMAIL: congtylevy2020@gmail.com',
  copyright: 'Bản quyền @ 2024 LÊ VY',
  name: 'VẬN TẢI DU LỊCH LÊ VY',
  INTRODUCE: 'GIỚI THIỆU',
  SERVICES: 'DỊCH VỤ',
  PROJECT: 'DỰ ÁN',
  maxim: 'Một trong những công ty vận tải container đầu tiên tại TP.HCM',
}
