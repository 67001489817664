import { memo } from 'react'
import { isEqual } from 'lodash'
import DashboardContainer from 'app/containers/Dashboard'

const DashboardCpn = () => {
  return (
    <>
      <DashboardContainer />
    </>
  )
}

export const DashboardPage = memo(DashboardCpn, isEqual)
