import styled from 'styled-components';

export const AdminContainer = styled.div`
  padding: 20px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
  }

  input, select {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    color: #000000;
  }

  input:focus, select:focus {
    border-color: #4caf50;
    outline: none;
  }
  
  img {
    display: block;
    margin-top: 10px;
    max-width: 100px;
    height: auto;
    border-radius: 4px;
  }
`;

export const NewsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
    color: #000000;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr:hover {
    background-color: #f9f9f9;
  }

  td img {
    border-radius: 4px;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    color: #000000;
  }

  .subtitle {
    font-size: 12px;
    color: #777;
    margin-top: 4px;
  }

  .status-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .status-icon {
    margin-right: 8px;
    color: #4caf50;
  }

  .edit-button {
    background-color: transparent;
    border: none;
    color: #4caf50;
    cursor: pointer;
    font-size: 14px;
  }
`;
