export default {
  companyName: 'LE VY COMMERCE, TRANSPORT AND TOURISM COMPANY LIMITED',
  taxCode: 'TAX CODE: 0315738496',
  headquarters:
    'HEADQUARTERS: 766/3 Provincial Road 10, Binh Tri Dong Ward, Binh Tan District',
  office: 'OFFICE: 90/4 Street No.2, Phu My Ward, District 7',
  copyright: 'Copyright @ 2024 LE VY',
  hotline: 'HOTLINE: (+84) 91 891 3838',
  emaillv: 'EMAIL: congtylevy2020@gmail.com',
  dashboard: 'Home',
  introduce: 'About us',
  services: 'Services',
  project: 'Projects',
  address: '90/4 Street 2, Phu My Ward, District 7',
  name: 'LE VY TRANSPORTATION TOURISM',
  avatar: 'Avatar',
  title: 'Title',
  content: 'Content',
  INTRODUCE: 'ABOUT US',
  SERVICES: 'SERVICES',
  maxim: 'One of the first container shipping companies in Ho Chi Minh City',
  team: 'Our team',
  partner: 'Partners',
}
