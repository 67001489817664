import { memo } from 'react'
import { isEqual } from 'lodash'
import MobileFooter from 'app/containers/Footer/MobileFooter'

const FooterCpn = () => {
  return (
    <>
      <MobileFooter />
    </>
  )
}
export const MobileFooterPage = memo(FooterCpn, isEqual)
