// Authentication
export const AUTH_PATH = '/auth'
export const LOGOUT_PATH = '/logout'
export const LOGIN_PATH = 'login'
export const AUTH_LOGIN_PATH = '/auth/login'
// profile
export const PROFILE_PATH = '/profile'

// Main
export const DASHBOARD_PATH = '/'
export const INTRODUCE_PATH = '/introduce'
export const SERVICES_PATH = '/services'
export const TEAM_PATH = '/team'
export const PROJECTS_PATH = '/projects'
export const PARTNER_PATH = '/partner'
export const CONTACT_PATH = '/contact'
export const FORGOT_PASSWORD_PATH = "/auth/forgot-password";
export const ENTER_CODE_PATH = "/auth/enter-code";
export const RESET_PASSWORD_PATH = "/auth/reset-password";

export const ADMIN_PATH = '/admin';
export const NEWSLIST_PATH = '/admin/newslist';
export const EDITNEWSLIST_PATH = '/admin/editnewslist/:newsId';