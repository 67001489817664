import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useResponsive } from 'parkway-web-common'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { DesktopMainLayout } from './layouts/DesktopMainLayout'
import { MobileMainLayout } from './layouts/MobileMainLayout'

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true)
  const [siderCollapsed, setSiderCollapsed] = useState(false)
  const { isDesktop, isTablet } = useResponsive()
  const location = useLocation()

  const toggleSider = () => setSiderCollapsed(!siderCollapsed)

  useEffect(() => {
    setIsTwoColumnsLayout(
      [DASHBOARD_PATH].includes(location.pathname) && isDesktop,
    )
  }, [location.pathname, isDesktop])

  const isMobile = !isDesktop && !isTablet

  return isMobile ? (
    <MobileMainLayout
      isTwoColumnsLayout={isTwoColumnsLayout}
      siderCollapsed={siderCollapsed}
      toggleSider={toggleSider}
    />
  ) : (
    <DesktopMainLayout
      isTwoColumnsLayout={isTwoColumnsLayout}
      siderCollapsed={siderCollapsed}
      toggleSider={toggleSider}
    />
  )
}

export default MainLayout
