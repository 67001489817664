import styled from 'styled-components'

export const ScrollWrapper = styled.div`
  @media (max-width: 768px) {
    .ant-card-body {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
    }
  }
`

export const BaseSection = styled.div`
  @media (max-width: 768px) {
    background-color: var(--background-color);
    padding: 0 3rem 4rem;
  }
`

export const HeaderContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 1rem 0 0;
  }
`

export const TitleWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    font-size: 1.3rem;
    color: midnightblue;
  }
`

export const ContentImageContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
`

export const TextContent = styled.div`
  @media (max-width: 768px) {
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5;
  }
`

export const ImageWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
    }
  }
`

export const ButtonContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      margin-left: 10px;
    }
  }
`

export const EditableContainer = styled.div`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
`

export const InputWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
      margin-bottom: 5px;
    }

    textarea {
      resize: vertical;
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 10px;
      font-size: 14px;
      width: 100%;
      box-sizing: border-box;
    }

    input[type='file'] {
      margin-top: 8px;
    }
  }
`

export const FileUploadContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

export const SectionSeparator = styled.hr`
  @media (max-width: 768px) {
    border: none;
    border-top: 1px solid #ccc;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`

export const TextCustom = styled.div`
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.5rem 0 1rem;
    line-height: 1.5;
    text-align: left;
    color: black;
  }
`

export const SectionsContainer = styled.div`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5rem;
  }
`
