import styled from 'styled-components'

export const NewsLists = styled.div`
  padding: 20px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 16px;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
`

export const TabContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 20px; 
  border-bottom: 2px solid #ddd;
  padding: 1rem 0 0;
`

export const TabButton = styled.button<{ active: boolean }>`
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:focus {
    outline: none;
  }

  color: #000000;
  background-color: ${({ active }) => (active ? '#f2f2f2' : 'transparent')};
`

export const NewsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
    color: #000000;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
    font-size: 16px;
  }

  tr:hover {
    background-color: #f9f9f9;
  }

  td img {
    border-radius: 4px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin: 0 1rem 0 0;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    color: #000000;
  }

  .subtitle {
    font-size: 12px;
    color: #777;
    margin-top: 4px;
  }

  .status-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .status-icon {
    margin-right: 8px;
    color: #4caf50;
  }

  .edit-button {
    background-color: transparent;
    border: none;
    color: #4caf50;
    cursor: pointer;
    font-size: 14px;
  }
`
