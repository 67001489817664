import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
  FooterContainer,
  CompanyInfo,
  CompanyInfoItem,
  ContactRight,
  ContactRightItem,
  ContactRightText,
} from './styles'
import R from 'app/assets/R'
import { useNavigate } from 'react-router'
import { AUTH_LOGIN_PATH } from 'app/components/router/route-path'
import { useTranslation } from 'react-i18next'

const Footer: React.FC = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const handleLogoClick = () => {
    navigate(AUTH_LOGIN_PATH)
  }

  const handleLanguageChange = (lng: string) => {
    const url = new URL(window.location.href)
    if (lng === 'vi') {
      url.searchParams.delete('language')
    } else {
      url.searchParams.set('language', lng)
    }

    navigate(url.pathname + url.search)

    i18n.changeLanguage(lng)
  }

  return (
    <FooterContainer>
      <CompanyInfo>
        <CompanyInfoItem>{t(R.strings.companyName)}</CompanyInfoItem>
        <CompanyInfoItem>{t(R.strings.taxCode)}</CompanyInfoItem>
        <CompanyInfoItem>{t(R.strings.headquarters)}</CompanyInfoItem>
        <CompanyInfoItem>{t(R.strings.office)}</CompanyInfoItem>
        <CompanyInfoItem
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        >
          {t(R.strings.copyright)}
        </CompanyInfoItem>
      </CompanyInfo>
      <ContactRight>
        <ContactRightItem>
          <FontAwesomeIcon icon={faPhoneAlt} />
          <ContactRightText>{t(R.strings.hotline)}</ContactRightText>
        </ContactRightItem>
        <ContactRightItem>
          <FontAwesomeIcon icon={faEnvelope} />
          <ContactRightText>{t(R.strings.emaillv)}</ContactRightText>
        </ContactRightItem>
        <ContactRightItem>
          <img
            src="https://storage.googleapis.com/logistic_bucket_dev/None/logo_bo_cong_thuong.png"
            alt={t('ministryOfIndustry')}
          />
        </ContactRightItem>
        <ContactRightItem>
          <img
            src="https://storage.googleapis.com/logistic_bucket_dev/None/ic_vietnam_flag.png"
            alt={t('vietnamese')}
            onClick={() => handleLanguageChange('vi')}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          <img
            src="https://storage.googleapis.com/logistic_bucket_dev/None/ic_united_kingdom_flag.png"
            alt={t('english')}
            onClick={() => handleLanguageChange('en')}
            style={{ cursor: 'pointer' }}
          />
        </ContactRightItem>
      </ContactRight>
    </FooterContainer>
  )
}

export default Footer
