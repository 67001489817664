import { LANGUAGE_SUPPORT, LanguageSupportType } from 'app/i18n/constant'
import { KEY_STORAGE } from '../key'

export const getToken = () => {
  return localStorage.getItem(KEY_STORAGE.TOKEN)
}

export const refetchToken = () => {
  return localStorage.setItem(KEY_STORAGE.TOKEN, '')
}

export const setToken = (token: string) => {
  return localStorage.setItem(KEY_STORAGE.TOKEN, token)
}

// config
export const getCurrentLanguage = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const languageFromUrl = urlParams.get('language')

  if (languageFromUrl === 'en') {
    return LANGUAGE_SUPPORT.en
  }
  return LANGUAGE_SUPPORT.vi
}

export const setCurrentLanguage = (language: LanguageSupportType | string) => {
  return localStorage.setItem(KEY_STORAGE.LANGUAGE, language)
}
