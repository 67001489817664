import styled from 'styled-components';

export const LayoutMaster = styled.div`
  display: flex;
`;

export const LayoutMain = styled.div`
  flex: 1;
  display: flex;
`;

export const MainContent = styled.div<{ $isTwoColumnsLayout?: boolean }>`
  flex: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

export const MainHeader = styled.div<{ isTwoColumnsLayout: boolean }>`
  ${({ isTwoColumnsLayout }) =>
    isTwoColumnsLayout
      ? `
    width: calc(100% - 200px);
    transition: width 0.2s;
  `
      : `
    width: 100%;
  `}
`;
