import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import MobileIntroduceContainer from 'app/containers/IntroduceMobile'

const IntroduceCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.introduce)}</PageTitle>
      <MobileIntroduceContainer />
    </>
  )
}
export const MobileIntroducePage = memo(IntroduceCpn, isEqual)
