import styled from 'styled-components'
import { BaseLayout } from 'app/components/common/BaseLayout'

export const LayoutMaster = styled(BaseLayout)`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const LayoutMain = styled(BaseLayout)`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
  header {
    display: block !important;
  }
}

`
export const MainHeader = styled.div<{ isTwoColumnsLayout: boolean }>`
  ${({ isTwoColumnsLayout }) =>
    isTwoColumnsLayout
      ? `
    width: calc(100% - 200px);
    transition: width 0.2s;
  `
      : `
    width: 100%;
  `}
`;
