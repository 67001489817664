import React, { useState } from 'react'
import {
  HeaderStyle,
  LogoImageStyle,
  LogoStyle,
  TopBarStyle,
  MenuIconStyle,
} from '../styles'
import MobileSliderMenu from '../../sider/SiderMenu/MobileSliderMenu'

export const MobileMainHeader: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true)

  const toggleMenu = () => {
    setCollapsed(prevCollapsed => !prevCollapsed)
  }

  return (
    <HeaderStyle>
      <TopBarStyle>
        <LogoStyle>
          <LogoImageStyle
            src="https://storage.googleapis.com/logistic_bucket_dev/None/logo_le_vy.jpg"
            alt="Logo"
          />
        </LogoStyle>

        <MenuIconStyle onClick={toggleMenu}>&#9776;</MenuIconStyle>
      </TopBarStyle>

      <MobileSliderMenu setCollapsed={setCollapsed} isCollapsed={collapsed} />
    </HeaderStyle>
  )
}
