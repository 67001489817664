import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import * as S from './styles'
import TextArea from 'antd/es/input/TextArea'
import { BaseButton } from 'app/components/common/BaseButton'

interface BannerProps {
  id: number
  title: string
  description: string
  imageUrl: string
}

interface EditableBannerProps {
  banner: BannerProps
  onEdit: (title: string, description: string, imageUrl: string) => void
  onDelete: () => void
  isEditing: boolean
}

const EditableBanner: React.FC<EditableBannerProps> = ({
  banner,
  onEdit,
  onDelete,
  isEditing,
}) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(banner.title)
  const [description, setDescription] = useState(banner.description)
  const [imageUrl, setImageUrl] = useState(banner.imageUrl)

  const handleSave = () => {
    if (!title.trim() && !description.trim() && !imageUrl.trim()) {
      onDelete()
    } else {
      onEdit(title, description, imageUrl)
    }
  }

  useEffect(() => {
    if (title !== banner.title || description !== banner.description) {
      handleSave()
    }
  }, [title, description])

  useEffect(() => {
    if (imageUrl !== banner.imageUrl) {
      handleSave()
    }
  }, [imageUrl])

  const splitTitle = (text: string) => {
    const words = text.split(' ')
    const firstPart = words.slice(0, 2).join(' ')
    const secondPart = words.slice(2).join(' ')
    return { firstPart, secondPart }
  }

  const { firstPart, secondPart } = splitTitle(title)

  return (
    <S.BaseSection>
      {isEditing ? (
        <>
          <S.EditableContainer>
            <S.InputWrapper>
              <label>{t(R.strings.title)}:</label>
              <TextArea
                value={title}
                onChange={e => setTitle(e.target.value)}
                onBlur={handleSave}
                placeholder={t(R.strings.title)}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <label>{t(R.strings.description)}:</label>
              <TextArea
                value={description}
                onChange={e => setDescription(e.target.value)}
                onBlur={handleSave}
                placeholder={t(R.strings.description)}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <label>{t(R.strings.upload_image)}:</label>
              <input
                type="text"
                value={imageUrl}
                onChange={e => setImageUrl(e.target.value)}
                onBlur={handleSave}
                placeholder={t(R.strings.upload_image)}
              />
            </S.InputWrapper>
          </S.EditableContainer>
          <S.ButtonContainer>
            <BaseButton onClick={onDelete}>{t(R.strings.delete)}</BaseButton>
          </S.ButtonContainer>
        </>
      ) : (
        <S.BannerContainer imageUrl={imageUrl}>
          <S.BannerContent>
            <S.Title>
              <div>{firstPart}</div>
              <div>{secondPart}</div>
            </S.Title>

            <S.Message
              dangerouslySetInnerHTML={{ __html: description }}
              className="ql-editor"
            />
            <S.ButtonsWrapper>
              <a href="#introduce">
                <S.BaseButton>{t(R.strings.introduce)}</S.BaseButton>
              </a>
              <a href="#services">
                <S.Button>{t(R.strings.services)}</S.Button>
              </a>
            </S.ButtonsWrapper>
          </S.BannerContent>
        </S.BannerContainer>
      )}
    </S.BaseSection>
  )
}

export default EditableBanner
