import React from 'react'
import { IntroducePage } from 'app/page/menu/introduce/desktop-introduce'
import { ServicesPage } from 'app/page/menu/services/desktop-services'
import { ProjectsPage } from 'app/page/menu/projects/desktop-projects'
import { TeamPage } from 'app/page/menu/team/desktop-team'
import { PartnerPage } from 'app/page/menu/partner/desktop-partner'
import { LayoutMain, LayoutMaster } from '../styles'
import MainSlider from '../../sider/MainSider'
import { MainContent } from '../../MainContent'
import { MainHeader } from '../../MainHeader'
import { FooterPage } from 'app/page/menu/footer/desktop-footer'
import { DashboardPage } from 'app/page/menu/dashboard/desktop-dashboard'

interface DesktopMainLayoutProps {
  isTwoColumnsLayout: boolean
  siderCollapsed: boolean
  toggleSider: () => void
}

export const DesktopMainLayout: React.FC<DesktopMainLayoutProps> = ({
  isTwoColumnsLayout,
  siderCollapsed,
  toggleSider,
}) => {
  return (
    <LayoutMaster>
      <MainSlider isCollapsed={siderCollapsed} setCollapsed={toggleSider} />
      <LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout} />
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div id="dashboard">
            <DashboardPage />
          </div>
          <div id="introduce">
            <IntroducePage />
          </div>
          <div id="services">
            <ServicesPage />
          </div>
          <div id="team">
            <TeamPage />
          </div>
          <div id="project">
            <ProjectsPage />
          </div>
          <div id="partner">
            <PartnerPage />
          </div>
          <div id="footer">
            <FooterPage />
          </div>
        </MainContent>
      </LayoutMain>
    </LayoutMaster>
  )
}
