import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import * as S from './styles';
import { AdminHeader } from '../AdminHeader';
import { AdminContent } from '../AdminContent';
import { AUTH_LOGIN_PATH } from 'app/components/router/route-path';

const AdminLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsTwoColumnsLayout(true);
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate(AUTH_LOGIN_PATH);
  };

  return (
    <S.LayoutMaster>
      <S.LayoutMain>
        <AdminHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <S.LogoutButton onClick={handleLogout}>Đăng xuất</S.LogoutButton>
        </AdminHeader>
        <AdminContent $isTwoColumnsLayout={isTwoColumnsLayout}>
          <Outlet />
        </AdminContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default AdminLayout;
