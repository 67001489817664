import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import { NEWSLIST_PATH } from 'app/components/router/route-path'
import { useAppDispatch } from 'app/redux/hooks'
import { doLogin } from 'app/redux/slices/authSlice'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as S from './styles'

interface LoginFormData {
  email: string
  password: string
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
}

const LoginContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(false)

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true)
    dispatch(doLogin(values))
      .unwrap()
      .then(() => {
        navigate(NEWSLIST_PATH)
      })
      .catch(() => undefined)
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Auth.FormWrapper>
      <Auth.FormBaseWrapper>
        <Auth.BaseFormWrapper>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initValues}
          >
            <Auth.FormTitle>{t(R.strings.login)}</Auth.FormTitle>
            <Auth.FormItem
              name="email"
              label={t(R.strings.email)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.email),
                  }),
                },
                {
                  pattern:
                    /^(?:\+84|0)\d{9,10}|^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: t(R.strings.regex_email),
                },
              ]}
            >
              <Auth.FormInput placeholder={"Nhập email"} />
            </Auth.FormItem>
            <Auth.FormItem
              label={t(R.strings.password)}
              name="password"
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.password),
                  }),
                },
              ]}
            >
              <Auth.FormInputPassword placeholder={t(R.strings.password)} />
            </Auth.FormItem>
            <Auth.ActionsWrapper>
              <Link to="/auth/forgot-password">
                <S.ForgotPasswordText>
                  {t(R.strings.forgot_password)}
                </S.ForgotPasswordText>
              </Link>
            </Auth.ActionsWrapper>
            <BaseForm.Item noStyle>
              <Auth.SubmitButton
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {t(R.strings.login)}
              </Auth.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </Auth.BaseFormWrapper>
      </Auth.FormBaseWrapper>
    </Auth.FormWrapper>
  )
}
export default LoginContainer
