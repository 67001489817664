import styled from 'styled-components'

export const SliderMenuContainer = styled.div<{ isCollapsed: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  z-index: 1000;
`

export const Overlay = styled.div<{ isCollapsed: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ isCollapsed }) => (isCollapsed ? 0 : 1)};
  pointer-events: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'auto')};
`

export const MenuContent = styled.div<{ isCollapsed: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  color: midnightblue;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? 'translateX(100%)' : 'translateX(0)'};
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
  padding: 20px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CloseButton = styled.div`
  font-size: 2rem;
  cursor: pointer;
  color: midnightblue;
  margin-bottom: auto;
`

export const LogoImageStyle = styled.img`
  height: 5rem;
`

export const HorizontalLine = styled.div`
  width: 100%;
  border-top: 1px solid midnightblue;
  margin: 10px 0;
`

export const MenuItems = styled.ul`
  list-style: none;
  width: 100%;

  li {
    margin: 1rem;
    cursor: pointer;
    color: midnightblue;
    font-size: 1.2rem;

    transition: background-color 180ms, color 180ms;

    &:hover {
      background-color: midnightblue;
      color: white;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }
`

export const ContactInfo = styled.div`
  margin-top: 20px;
  font-size: 1.2rem;
  color: midnightblue;
`

export const ContactItemStyle = styled.div`
  margin-bottom: 1rem;
  display: flex;
`

export const ContactRightText = styled.span`
  font-size: 1.1rem;
  font-weight: bold;
`
