
function strings(){
    return{
        yes: "yes",
        no: "no",
        fill_barcode: "fill_barcode",
        see_more: "see_more",
        choose: "choose",
        find: "find",
        address: "address",
        language: "language",
        vietnamese: "vietnamese",
        english: "english",
        foreign_language: "foreign_language",
        home: "home",
        phone: "phone",
        email: "email",
        send: "send",
        next: "next",
        back: "back",
        minute: "minute",
        hour: "hour",
        send_code: "send_code",
        login: "login",
        user_name: "user_name",
        password: "password",
        re_password: "re_password",
        re_password_not_match: "re_password_not_match",
        change_password: "change_password",
        change_password_success: "change_password_success",
        login_success: "login_success",
        forgot_password: "forgot_password",
        require_field: "require_field",
        required: "required",
        minimum_field: "minimum_field",
        maximum_field: "maximum_field",
        register: "register",
        confirm_password: "confirm_password",
        verify_by_phone: "verify_by_phone",
        verify_by_email: "verify_by_email",
        code_verify: "code_verify",
        message_verify_phone: "message_verify_phone",
        message_verify_email: "message_verify_email",
        re_send_verify_code: "re_send_verify_code",
        resend: "resend",
        time_resend_verify_code: "time_resend_verify_code",
        verify: "verify",
        logout: "logout",
        description: "description",
        full_name: "full_name",
        regex_name: "regex_name",
        regex_user_name: "regex_user_name",
        using_by: "using_by",
        require_email: "require_email",
        require_contact_name: "require_contact_name",
        regex_email: "regex_email",
        regex_phone: "regex_phone",
        send_contact_success: "send_contact_success",
        please_enter_correct_format: "please_enter_correct_format",
        dashboard: "dashboard",
        development: "development",
        profile: "profile",
        week: "week",
        choose_week: "choose_week",
        degree_content_placeholder_upload_document: "degree_content_placeholder_upload_document",
        upload: "upload",
        cancel: "cancel",
        confirm: "confirm",
        not_exist_page: "not_exist_page",
        search: "search",
        export_excel: "export_excel",
        read_all: "read_all",
        view_all: "view_all",
        download_example_import_file: "download_example_import_file",
        import: "import",
        import_file: "import_file",
        introduce: "introduce",
        services: "services",
        project: "project",
        contact: "contact",
        company_info: "company_info",
        team_management: "team_management",
        development_history: "development_history",
        new_section: "new_section",
        new_content: "new_content",
        save_all: "save_all",
        edit: "edit",
        add_new_section: "add_new_section",
        delete: "delete",
        upload_image: "upload_image",
        content: "content",
        title: "title",
        subtitle: "subtitle",
        service_list: "service_list",
        content_services: "content_services",
        project_list: "project_list",
        project_content: "project_content",
        pictures_and_videos: "pictures_and_videos",
        contact_via_zalo: "contact_via_zalo",
        contact_via_phone: "contact_via_phone",
        add_new_contact: "add_new_contact",
        enter_email_or_phone: "enter_email_or_phone",
        remember_me: "remember_me",
        admin: "admin",
        team: "team",
        partner: "partner",
        enter_email: "enter_email",
        reset_password: "reset_password",
        enter_verification_code: "enter_verification_code",
        enter_new_password: "enter_new_password",
        new_password: "new_password",
        avatar: "avatar",
        news_management: "news_management",
        task: "task",
        companyName: "companyName",
        taxCode: "taxCode",
        headquarters: "headquarters",
        office: "office",
        hotline: "hotline",
        emaillv: "emaillv",
        copyright: "copyright",
        name: "name",
        INTRODUCE: "INTRODUCE",
        SERVICES: "SERVICES",
        PROJECT: "PROJECT",
        maxim: "maxim"
}}
export default strings
        