import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 2rem 15rem;
  background-color: midnightblue;
  color: white;
  border-top: 1px solid #e8e8e8;
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CompanyInfoItem = styled.div`
  margin-bottom: 1rem;
`;

export const ContactRight = styled.div`
  max-width: 50%;
`;

export const ContactRightItem = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  &:last-child img {
    width: 1.5rem;
    height: auto;
  }

  img {
    width: 8rem;
    height: auto;
  }
`;

export const ContactRightText = styled.span`
  margin-left: 8px;
`;
