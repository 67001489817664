import styled from 'styled-components';

export const LayoutMaster = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

export const LayoutMain = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export const LogoutButton = styled.button`
  background-color: white;
  color: midnightblue;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
`;