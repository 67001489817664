import React from 'react';
import { useResponsive } from 'parkway-web-common';
import { DesktopMainHeader } from './layouts/DesktopMainHeader';
import { MobileMainHeader } from './layouts/MobileMainHeader';

interface MainHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const MainHeader: React.FC<MainHeaderProps> = () => {
  const { isTablet } = useResponsive();

  return isTablet ? (
    <DesktopMainHeader />
  ) : (
    <MobileMainHeader />
  );
};
