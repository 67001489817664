import React, { useState } from 'react'
import R from 'app/assets/R'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'
import {
  CompanyNameStyle,
  ContactInfoStyle,
  ContactItemStyle,
  ContactItemWrapper,
  HeaderStyle,
  LogoImageStyle,
  LogoStyle,
  TopBarStyle,
} from '../styles'
import SliderMenu from '../../sider/SiderMenu/DesktopSliderMenu'

export const DesktopMainHeader: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false)

  const toggleMenu = () => {
    setCollapsed(!collapsed)
  }

  return (
    <HeaderStyle>
      <TopBarStyle>
        <LogoStyle>
          <LogoImageStyle src="https://storage.googleapis.com/logistic_bucket_dev/None/logo_le_vy.jpg" alt="Logo" />
        </LogoStyle>

        <CompanyNameStyle>{t(R.strings.name)}</CompanyNameStyle>

        <ContactInfoStyle>
          <ContactItemWrapper>
            <ContactItemStyle>
              <FontAwesomeIcon
                icon={faPhoneAlt}
                style={{ marginRight: '8px' }}
              />
              (+84) 91 891 3838
            </ContactItemStyle>
            <ContactItemStyle>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ marginRight: '8px' }}
              />
              {t(R.strings.address)}
            </ContactItemStyle>
          </ContactItemWrapper>
        </ContactInfoStyle>
      </TopBarStyle>
      <SliderMenu setCollapsed={toggleMenu} isCollapsed={collapsed} />
    </HeaderStyle>
  )
}
