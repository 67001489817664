import styled from 'styled-components'

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem 1rem;
  background-color: midnightblue;
  color: white;
  border-top: 1px solid #e8e8e8;
  text-align: left;
`

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const CompanyInfoItem = styled.div`
  margin-bottom: 0.8rem;
  font-size: 1rem;
  line-height: 1.4;
  text-align: left;
`

export const ContactRight = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ContactRightItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:last-child img {
    width: 2.5rem;
    height: auto;
  }
`

export const ContactRightText = styled.span`
  padding: 0 0 1rem 0;
  font-size: 1rem;
`

export const LogoStyle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`
export const LogoImageStyle = styled.img`
  height: 5rem;
`
export const Separator = styled.hr`
  width: 80%;
  border: none;
  border-top: 1.5px solid white;
  margin: 0 0 1rem 0;
`
