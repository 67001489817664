import R from 'app/assets/R'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BaseText from '../BaseText'
import * as S from './styles'

export const NotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.NotFoundWrapper>
      <S.ImgWrapper>
        {/* <BaseImage
          src={R.images.ic_parkway}
          alt="Not found"
          preview={false}
        /> */}
        <BaseText children={'UEH'} fontWeight="bold" fontSize="xxxxl" />
      </S.ImgWrapper>
      <S.Text>{t(R.strings.not_exist_page)}</S.Text>
    </S.NotFoundWrapper>
  )
}
