import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'

export const ScrollWrapper = styled.div`
  @media (max-width: 768px) {
    .ant-card-body {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
    }
  }
`

interface BannerContainerProps {
  imageUrl: string
}

export const BannerContainer = styled.div<BannerContainerProps>`
  @media (max-width: 768px) {
    background-image: url(${props => props.imageUrl});
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
    }
  }
`

export const BannerContent = styled.div`
  @media (max-width: 768px) {
    z-index: 1;
  }
`

export const Title = styled.div`
  @media (max-width: 768px) {
    font-size: 2.5rem;
    color: white;
    font-weight: bold;
    line-height: 1.5;
  }
`

export const Message = styled.div`
  @media (max-width: 768px) {
    font-size: 1.5rem;
    color: white;
    padding: 0 0 1.5rem 0;
    line-height: 1.5;
  }
`

export const InputWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    label {
      font-weight: bold;
      margin-bottom: 5px;
    }

    input,
    textarea {
      padding: 10px;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }
  }
`

export const FileUploadContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 10px;

    input[type='file'] {
      margin-top: 8px;
    }
  }
`

export const EditableContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
`

export const ButtonContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      margin-left: 10px;
    }
  }
`

export const BaseSection = styled.div`
  @media (max-width: 768px) {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    &.editing {
      max-width: 100%;
    }
  }
`

export const ButtonsWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    gap: 2rem;
    justify-content: center;

    a {
      display: flex;
      justify-content: center;
    }
  }
`

export const BaseButton = styled.div`
  @media (max-width: 768px) {
    background-color: white;
    color: midnightblue;
    border-radius: 0.5rem;
    font-weight: bold;
    padding: 1rem 1rem;

    &:hover {
      background-color: lightgray;
    }
  }
`

export const Button = styled.div`
  @media (max-width: 768px) {
    background-color: white;
    color: midnightblue;
    border-radius: 0.5rem;
    font-weight: bold;
    padding: 1rem 1.5rem;

    &:hover {
      background-color: lightgray;
    }
  }
`

export const SectionSeparator = styled.hr`
  @media (max-width: 768px) {
    border: none;
    border-top: 1px solid #ccc;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`

export const SectionsContainer = styled.div`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5rem;
  }
`
