import React from 'react';
import * as S from './styles';

interface AdminHeaderProps {
  isTwoColumnsLayout: boolean;
  children?: React.ReactNode;
}

export const AdminHeader: React.FC<AdminHeaderProps> = ({ isTwoColumnsLayout, children }) => {
  return (
    <S.HeaderWrapper isTwoColumnsLayout={isTwoColumnsLayout}>
      <div>Admin Dashboard</div>
      {children && <div>{children}</div>}
    </S.HeaderWrapper>
  );
};
