import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as S from './styles'
import { BaseButton } from 'app/components/common/BaseButton'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { NEWSLIST_PATH } from 'app/components/router/route-path'
import { t } from 'i18next'
import R from 'app/assets/R'
import { requestUpdateContent } from 'app/api/auth'
import 'react-quill/dist/quill.snow.css'

const fonts = [
  { value: 'inter', label: 'Inter' },
  { value: 'roboto', label: 'Roboto' },
  { value: 'arial', label: 'Arial' },
  { value: 'times-new-roman', label: 'Times New Roman' },
  { value: 'georgia', label: 'Georgia' },
  { value: 'tahoma', label: 'Tahoma' },
  { value: 'verdana', label: 'Verdana' },
  { value: 'courier-new', label: 'Courier New' },
  { value: 'helvetica', label: 'Helvetica' },
]

const fontValues = fonts.map(font => font.value)

const Font = ReactQuill.Quill.import('formats/font') as any
Font.whitelist = fontValues
ReactQuill.Quill.register(Font, true)

const Align = ReactQuill.Quill.import('formats/align') as any
Align.whitelist = ['right', 'center', 'justify', 'left']
ReactQuill.Quill.register(Align, true)

const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-font">
      {fonts.map(font => (
        <option value={font.value} key={font.value}>
          {font.label}
        </option>
      ))}
    </select>
    <select className="ql-header" defaultValue="">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="3">Heading 3</option>
      <option value="4">Heading 4</option>
      <option value="5">Heading 5</option>
      <option value="6">Heading 6</option>
      <option value="">Normal</option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <select className="ql-color"></select>
    <select className="ql-background"></select>
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    <select className="ql-align">
      <option value=""></option>
      <option value="center"></option>
      <option value="right"></option>
      <option value="justify"></option>
    </select>
    <button className="ql-link"></button>
    <button className="ql-image"></button>
  </div>
)

const modules = {
  toolbar: {
    container: '#toolbar',
  },
}

const formats = [
  'font',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'background',
  'align',
]

const EditNewsPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const initialNewsState = {
    id: '',
    title: '',
    description: '',
    imageUrl: '',
    position: 0,
  }

  const [news, setNews] = useState(location.state?.news || initialNewsState)

  const [imageFile, setImageFile] = useState<File | null>(null)
  const imageDefaultUrl = location.state?.news?.document?.url || null
  const [imagePreview, setImagePreview] = useState<string | null>(
    imageDefaultUrl,
  )

  const handleSave = async () => {
    try {
      const requestPayload: any = {
        title: news.title,
        description: news.description,
        position: news.position,
      }

      if (imageFile) {
        requestPayload.file = imageFile
      }

      const response = await requestUpdateContent(news.id, requestPayload)

      if (response) {
        setNews({ ...news, id: response })

        // Lấy ngôn ngữ hiện tại từ URL
        const currentLanguage =
          new URLSearchParams(location.search).get('language') || 'vn'

        // Điều hướng lại trang NewsListContainer với ngôn ngữ hiện tại
        navigate(`${NEWSLIST_PATH}?language=${currentLanguage}`, {
          state: {
            updatedNews: { ...news, id: response },
            refresh: true,
            language: currentLanguage, // Chuyển ngôn ngữ qua state
          },
        })
      } else {
        console.error('No updated news returned from API')
      }
    } catch (error) {
      console.error('Failed to update news:', error)
    }
  }

  return (
    <S.AdminContainer>
      <h1>{t(R.strings.edit)}</h1>
      <S.NewsTable>
        <S.FormGroup>
          <label>{t(R.strings.avatar)}</label>
          <input
            type="file"
            accept="image/*"
            onChange={e => {
              if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0]

                setImageFile(file)
                setImagePreview(URL.createObjectURL(file))
              }
            }}
          />

          {imagePreview && (
            <img
              src={imagePreview}
              alt="Avatar"
              style={{ marginTop: '10px', width: '100px', height: '100px' }}
            />
          )}
        </S.FormGroup>
        <S.FormGroup>
          <label>{t(R.strings.title)}</label>
          <input
            type="text"
            value={news.title}
            onChange={e => setNews({ ...news, title: e.target.value })}
          />
        </S.FormGroup>
        <S.FormGroup>
          <label>{t(R.strings.content)}</label>
          <CustomToolbar />
          <ReactQuill
            theme="snow"
            value={news.description}
            onChange={value => setNews({ ...news, description: value })}
            modules={modules}
            formats={formats}
          />
        </S.FormGroup>

        <BaseButton onClick={handleSave}>{t(R.strings.save_all)}</BaseButton>
      </S.NewsTable>
    </S.AdminContainer>
  )
}

export default EditNewsPage
