import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Auth from 'app/components/layouts/AuthLayout/styles';
import { BaseForm } from 'app/components/common/forms/BaseForm';
import R from 'app/assets/R';

interface ResetPasswordFormData {
  newPassword: string;
  confirmPassword: string;
}

export const initResetPasswordValues: ResetPasswordFormData = {
  newPassword: '',
  confirmPassword: '',
};

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Gửi yêu cầu đổi mật khẩu tại đây
      // await dispatch(doResetPassword(values)).unwrap();
      navigate('/auth/login');
    } catch (error) {
      console.error('Đổi mật khẩu thất bại', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Auth.FormWrapper>
      <Auth.FormBaseWrapper>
        <Auth.BaseFormWrapper>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initResetPasswordValues}
          >
            <Auth.FormTitle>{t(R.strings.reset_password)}</Auth.FormTitle>
            <Auth.FormItem
              name="newPassword"
              label={t(R.strings.new_password)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.new_password),
                  }),
                },
                {
                  min: 6,
                  message: t(R.strings.re_password),
                },
              ]}
            >
              <Auth.FormInput.Password placeholder={t(R.strings.enter_new_password)} />
            </Auth.FormItem>
            <Auth.FormItem
              name="confirmPassword"
              label={t(R.strings.re_password)}
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.re_password),
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t(R.strings.re_password_not_match)));
                  },
                }),
              ]}
            >
              <Auth.FormInput.Password placeholder={t(R.strings.re_password)} />
            </Auth.FormItem>
            <BaseForm.Item noStyle>
              <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                {t(R.strings.reset_password)}
              </Auth.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </Auth.BaseFormWrapper>
      </Auth.FormBaseWrapper>
    </Auth.FormWrapper>
  );
};

export default ResetPassword;
