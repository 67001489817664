import { useResponsive } from 'parkway-web-common'
import React from 'react'
import { Overlay } from '../../../../common/Overlay'

interface MainSliderProps {
  isCollapsed: boolean
  setCollapsed: (isCollapsed: boolean) => void
}

const MainSlider: React.FC<MainSliderProps> = ({
  isCollapsed,
  setCollapsed,
}) => {
  const { mobileOnly} = useResponsive()

 

  const toggleSlider = () => setCollapsed(!isCollapsed)

  return (
    <>
      {mobileOnly && <Overlay onClick={toggleSlider} show={!isCollapsed} />}
    </>
  )
}

export default MainSlider
