import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
import authReducer from 'app/redux/slices/authSlice'
import nightModeReducer from 'app/redux/slices/nightModeSlice'
import pwaReducer from 'app/redux/slices/pwaSlice'
import themeReducer from 'app/redux/slices/themeSlice'
import userReducer from 'app/redux/slices/userSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    nightMode: nightModeReducer,
    theme: themeReducer,
    pwa: pwaReducer,
    user: userReducer
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
