import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Auth from 'app/components/layouts/AuthLayout/styles';
import { BaseForm } from 'app/components/common/forms/BaseForm';
import R from 'app/assets/R';

interface EnterCodeFormData {
  code: string;
}

export const initEnterCodeValues: EnterCodeFormData = {
  code: '',
};

const EnterCode: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = () => {
    // Sau khi xử lý mã xác thực thành công, chuyển hướng đến trang đổi mật khẩu
    navigate('/auth/reset-password');
  };

  return (
    <Auth.FormWrapper>
      <Auth.FormBaseWrapper>
        <Auth.BaseFormWrapper>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initEnterCodeValues}
          >
            <Auth.FormTitle>{t(R.strings.reset_password)}</Auth.FormTitle>
            <Auth.FormItem
              name="code"
              label={t(R.strings.code_verify)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.code_verify),
                  }),
                },
                {
                  pattern: /^\d{6}$/,
                  message: t(R.strings.code_verify),
                },
              ]}
            >
              <Auth.FormInput placeholder={t(R.strings.enter_verification_code)} />
            </Auth.FormItem>
            <BaseForm.Item noStyle>
              <Auth.SubmitButton type="primary" htmlType="submit">
                {t(R.strings.verify)}
              </Auth.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </Auth.BaseFormWrapper>
      </Auth.FormBaseWrapper>
    </Auth.FormWrapper>
  );
};

export default EnterCode;
