import { BASE_URL_LOCAL } from 'parkway-web-common'

const TIMESLOT_STEP = 15

const NOTE_MAX_LENGTH = 300

const ENV_CONFIG = {
  BASE_URL_LOCAL: process.env.REACT_APP_BASE_URL_LOCAL ?? BASE_URL_LOCAL,
  TINY_KEY: process.env.REACT_APP_TINY_KEY ?? '',
  API_ENDPOINT: 'https://api.levylogistics.vn/',
}

export {
  ENV_CONFIG,
  NOTE_MAX_LENGTH,
  TIMESLOT_STEP,
}
