import R from 'app/assets/R'
import { PermissionEnum } from 'app/common/enum'
import {
  DASHBOARD_PATH,
  INTRODUCE_PATH,
  PARTNER_PATH,
  PROJECTS_PATH,
  SERVICES_PATH,
  TEAM_PATH,
} from 'app/components/router/route-path'
import React from 'react'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
  iconFocus?: React.ReactNode
  permission?: PermissionEnum
  isLink?: boolean
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: R.strings.dashboard,
    key: R.strings.dashboard,
    url: DASHBOARD_PATH,
  },
  {
    title: R.strings.introduce,
    key: R.strings.introduce,
    url: INTRODUCE_PATH,
  },
  {
    title: R.strings.services,
    key: R.strings.services,
    url: SERVICES_PATH,
  },
  {
    title: R.strings.team,
    key: R.strings.team,
    url: TEAM_PATH,
  },
  {
    title: R.strings.project,
    key: R.strings.project,
    url: PROJECTS_PATH,
  },
  {
    title: R.strings.partner,
    key: R.strings.partner,
    url: PARTNER_PATH,
  },
]
