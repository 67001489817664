import { LeftOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput as CommonInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword as CommonInputPassword } from 'app/components/common/inputs/InputPassword'
import { FONT_SIZE, FONT_WEIGHT, media } from 'parkway-web-common'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const FormWrapper = styled.div`
  overflow: none;
  background-color: rgba(var(--background-rgb-color), 0.93);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and ${media.xs} {
    width: 20.75rem;
  }

  @media only screen and ${media.md} {
    width: 35rem;
  }
`

export const FormTitle = styled.div`
  color: var(--primary-color);

  @media only screen and ${media.xs} {
    margin: 1.5rem 2rem;
    font-size: ${FONT_SIZE.lg};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.5625rem;
  }

  @media only screen and ${media.md} {
    margin-bottom: 0.875rem;
    font-size: ${FONT_SIZE.xxl};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.9375rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 0.9375rem;
    font-size: ${FONT_SIZE.xxxl};
    font-weight: ${FONT_WEIGHT.extraBold};
    line-height: 2.125rem;
  }
`

export const FormCheckbox = styled(BaseCheckbox)`
  display: flex;
  padding-left: 0.15rem;

  & .ant-checkbox-inner {
    border-radius: 3px;
    transform: scale(1.25rem);
  }

  & .ant-checkbox-input {
    transform: scale(1.25rem);
  }
`

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & .ant-form-item-label {
    padding: 0px;
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`

export const FormInput = styled(CommonInput)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`

export const FormInputPassword = styled(CommonInputPassword)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
`

export const Text = styled.span`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const LinkText = styled(Text)`
  text-decoration: underline;
  color: var(--primary-color);
`

export const SubmitButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
`

export const SocialButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`

export const FooterWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
`

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`

export const BackWrapper = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`

export const SocialIconWrapper = styled.div`
  display: flex;
  margin-right: 0.8125rem;
  @media only screen and ${media.xs} {
    margin-right: 0.625rem;
  }

  @media only screen and ${media.md} {
    margin-right: 0.8125rem;
  }
`

export const InformationWrapper = styled.img`
  height: 100%;
  width: 50%;
  object-fit: content;
`

export const FormBaseWrapper = styled.div`
  padding: 1.75rem;
  width: 100%;
`

export const NameSystemDisplay = styled.p`
  color: var(--primary1-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
  margin-top: 16px;
`
export const DescriptionSystemDisplay = styled.p`
  color: var(--primary1-color);
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`
export const LogoSystemDisplay = styled.img`
  height: 52px;
`

export const BaseFormWrapper = styled.div`
  width: 100%;
`
