import { ApiClient } from 'app/service/api-service'
import { AuthEndPoint, ContentEndPoint } from './constant'
import {
  EditContentItemRequest,
  IPayloadNewPassword,
  LoginRequest,
  ModuleType,
  ResetPasswordRequest,
  SecurityCodePayload,
  SignUpRequest,
} from './model'

// Authentication API Calls
export const requestPostLogin = async (body: LoginRequest) => {
  try {
    const response = await ApiClient.Post({ url: AuthEndPoint.LOGIN, body })
    return response
  } catch (error) {
    console.error('Login failed:', error)
    throw error
  }
}

export const requestGetContentList = async (language: string) => {
  try {
    const response = await ApiClient.Get({
      url: ContentEndPoint.GET_CONTENT + '?language=' + language,
      params: {},
    })
    return response.data ?? response
  } catch (error) {
    console.error('Failed to fetch contents:', error)
    throw error
  }
}

export const requestGetContentDetail = async (id: string) => {
  try {
    const response = await ApiClient.Get({
      url: ContentEndPoint.GET_CONTENT_BY_ID.replace('{id}', id),
      params: {},
    })
    return response
  } catch (error) {
    console.error('Failed to fetch content detail:', error)
    throw error
  }
}

export const requestPostSignUp = async (body: SignUpRequest) => {
  try {
    const response = await ApiClient.Post({ url: AuthEndPoint.SIGN_UP, body })
    return response.data
  } catch (error) {
    console.error('Sign Up failed:', error)
    throw error
  }
}

export const requestPostResetPassword = async (body: ResetPasswordRequest) => {
  try {
    const response = await ApiClient.Post({
      url: AuthEndPoint.FORGOT_PASSWORD,
      body,
    })
    return response.data
  } catch (error) {
    console.error('Reset Password failed:', error)
    throw error
  }
}

export const requestPostVerifySecurityCode = async (
  body: SecurityCodePayload,
) => {
  try {
    const response = await ApiClient.Post({
      url: AuthEndPoint.VERITY_CODE,
      body,
    })
    return response.data
  } catch (error) {
    console.error('Verify Security Code failed:', error)
    throw error
  }
}

export const requestSetNewPassword = async (body: IPayloadNewPassword) => {
  try {
    const response = await ApiClient.Put({
      url: AuthEndPoint.NEW_PASSWORD,
      body,
    })
    return response.data
  } catch (error) {
    console.error('Set New Password failed:', error)
    throw error
  }
}

export const requestUpdateContent = async (
  id: string,
  data: EditContentItemRequest,
) => {
  try {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('description', data.description)
    formData.append('position', data.position.toString())
    if (data.file) {
      formData.append('file', data.file)
    }

    const response = await ApiClient.Put({
      url: ContentEndPoint.UPDATE_CONTENT.replace('{id}', id),
      body: formData,
    })

    if (response) {
      return response.data ? response.data : response
    } else {
      console.error('No updated news returned from API')
      return null
    }
  } catch (error) {
    console.error('Error updating content:', error)
    throw error
  }
}

export const uploadImage = async (id: string, imageBlob: string) => {
  try {
    const formData = new FormData()
    formData.append('file', imageBlob)

    const response = await ApiClient.Put({
      url: ContentEndPoint.UPDATE_CONTENT.replace('{id}', id),
      body: formData,
    })

    if (response && response.data) {
      return response.data.url
    } else {
      console.error('Failed to upload image, server response:', response)
      throw new Error('Failed to upload image')
    }
  } catch (error) {
    console.error('Upload image failed:', error)
    throw error
  }
}

export const requestGetHomePageContent = async () => {
  try {
    const response = await ApiClient.Get({
      url: ContentEndPoint.GET_CONTENT,
      params: { module: ModuleType.Banner },
    })
    return response.data ? response.data : response
  } catch (error) {
    console.error('Failed to fetch homepage content:', error)
    throw error
  }
}
