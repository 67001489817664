import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as S from './styles'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { ContentItem, ModuleType, requestGetContentList } from 'app/api/auth'
import EditableSection from './hook'
import R from 'app/assets/R'
import { t } from 'i18next'

const MobileIntroduceContainer: React.FC = () => {
  const [contentList, setContentList] = useState<ContentItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const language = searchParams.get('language') || 'vn'

  useEffect(() => {
    const fetchContentList = async () => {
      try {
        const response = await requestGetContentList(language)
        console.log('API Response:', response)
        if (response) {
          const filteredContent = response.filter(
            (item: ContentItem) => item.module === ModuleType.AboutUs,
          )
          setContentList(filteredContent)
          if (filteredContent.length === 0) {
            setError('No content available.')
          }
        } else {
          console.log('No content found in API response.')
          setError('No content found.')
        }
      } catch (error) {
        setError('Failed to fetch content list')
        console.error('Failed to fetch content list:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchContentList()
  }, [language])

  const handleEditSection = (
    id: number,
    title: string,
    description: string,
    imageUrl: string,
  ) => {
    setContentList(prevList =>
      prevList.map(section =>
        section.id === id
          ? { ...section, title, description, imageUrl }
          : section,
      ),
    )
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (contentList.length === 0) {
    return <div>No content available.</div>
  }

  return (
    <S.ScrollWrapper>
      <S.HeaderContainer>
        <S.TitleWrapper>
          <HeaderPage titleI18nKey={t(R.strings.INTRODUCE)} />
        </S.TitleWrapper>
      </S.HeaderContainer>
      <S.SectionsContainer>
        {contentList.map(section => {
          const imageUrl = section.document?.url || section.imageUrl
          return (
            <EditableSection
              key={section.id}
              section={{ ...section, imageUrl }}
              index={section.position}
              onEdit={(title, description, imageUrl) =>
                handleEditSection(section.id, title, description, imageUrl)
              }
              onDelete={() =>
                setContentList(prevList =>
                  prevList.filter(item => item.id !== section.id),
                )
              }
              isEditing={false}
            />
          )
        })}
      </S.SectionsContainer>
      <S.SectionSeparator style={{ textAlign: 'center' }}></S.SectionSeparator>
    </S.ScrollWrapper>
  )
}

export default MobileIntroduceContainer
