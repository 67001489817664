// Message code define
export const AuthMessageCode = {
  AUTH_SUCCESS: '10001',
  AUTH_WRONG_USERNAME_PASSWORD: '10002',
};

// Action key
export const AuthActionKey = {
  AUTH_ACTION_KEY: 'auth/doLogin',
};

// Authentication Endpoints
export const AuthEndPoint = {
  LOGIN: 'api/v1/Accounts/login',
  SIGN_UP: 'auth/signUp',
  FORGOT_PASSWORD: 'auth/forgot-password',
  VERITY_CODE: 'auth/verify-security-code',
  NEW_PASSWORD: 'account/change-password',
};

// Content Management Endpoints
export const ContentEndPoint = {
  GET_CONTENT: 'api/v1/Contents',
  GET_CONTENT_BY_ID: 'api/v1/Contents/{id}',
  CREATE_CONTENT: 'api/v1/Contents',
  UPDATE_CONTENT: 'api/v1/Contents/{id}',
  DELETE_CONTENT: 'api/v1/Contents/{id}',
  FILTER_CONTENT: 'api/v1/Contents/filter',
};
