import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import * as S from './styles'
import { BaseButton } from 'app/components/common/BaseButton'
import TextArea from 'antd/es/input/TextArea'

interface Section {
  id: number
  title: string
  description: string
  imageUrl: string
}

interface EditableSectionProps {
  section: Section
  index: number
  onEdit: (title: string, description: string, imageUrl: string) => void
  onDelete: () => void
  isEditing: boolean
}

const EditableSection: React.FC<EditableSectionProps> = ({
  section,
  onEdit,
  onDelete,
  isEditing,
}) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(section.title)
  const [description, setDescription] = useState(section.description)
  const [imageUrl, setImageUrl] = useState(section.imageUrl)
  const [isImageError, setIsImageError] = useState(false)

  const handleSave = () => {
    if (!title.trim() && !description.trim() && !imageUrl.trim()) {
      onDelete()
    } else {
      onEdit(title, description, imageUrl)
    }
  }

  // Save changes when title or description changes
  useEffect(() => {
    if (title !== section.title || description !== section.description) {
      handleSave()
    }
  }, [title, description])

  useEffect(() => {
    if (imageUrl !== section.imageUrl) {
      handleSave()
    }
  }, [imageUrl])

  // Handle image URL input instead of file upload
  const handleImageUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(event.target.value)
  }

  return (
    <S.BaseSection>
      {isEditing ? (
        <>
          <S.EditableContainer>
            <S.InputWrapper>
              <label>{t(R.strings.title)}:</label>
              <TextArea
                value={title}
                onChange={e => setTitle(e.target.value)}
                onBlur={handleSave}
                placeholder={t(R.strings.title)}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <label>{t(R.strings.description)}:</label>
              <TextArea
                value={description}
                onChange={e => setDescription(e.target.value)}
                onBlur={handleSave}
                placeholder={t(R.strings.description)}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <label>{t(R.strings.upload_image)}:</label>
              <input
                type="text"
                value={imageUrl}
                onChange={handleImageUrlChange}
                placeholder={t(R.strings.upload_image)}
              />
            </S.InputWrapper>
          </S.EditableContainer>
          <S.ButtonContainer>
            <BaseButton onClick={onDelete}>{t(R.strings.delete)}</BaseButton>
          </S.ButtonContainer>
        </>
      ) : (
        <>
          <S.ContentImageContainer>
            <S.ImageWrapper>
              {!isImageError && imageUrl && (
                <img
                  src={imageUrl}
                  alt="Content related"
                  onError={() => setIsImageError(true)}
                />
              )}
            </S.ImageWrapper>
            <S.TextContent>
              <S.IconTitleWrapper>
                <S.Title>{section.title}</S.Title>
              </S.IconTitleWrapper>
              <S.TextCustom
                dangerouslySetInnerHTML={{ __html: section.description }}
              />
            </S.TextContent>
          </S.ContentImageContainer>
        </>
      )}
    </S.BaseSection>
  )
}

export default EditableSection
