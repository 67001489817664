import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import R from 'app/assets/R'
// import { useAppDispatch } from 'app/redux/hooks'
// import { doForgotPassword } from './styles'

interface ForgotPasswordFormData {
  email: string
}

export const initForgotPasswordValues: ForgotPasswordFormData = {
  email: '',
}

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      // await dispatch(doForgotPassword(values)).unwrap()
      navigate('/auth/enter-code')
    } catch (error) {
      console.error('Gửi yêu cầu quên mật khẩu thất bại', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Auth.FormWrapper>
      <Auth.FormBaseWrapper>
        <Auth.BaseFormWrapper>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initForgotPasswordValues}
          >
            <Auth.FormTitle>{t(R.strings.forgot_password)}</Auth.FormTitle>
            <Auth.FormItem
              name="email"
              label={t(R.strings.require_email)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.require_email),
                  }),
                },
                {
                  pattern:
                    /^(?:\+84|0)\d{9,10}|^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: t(R.strings.regex_email),
                },
              ]}
            >
              <Auth.FormInput placeholder={t(R.strings.enter_email)} />
            </Auth.FormItem>
            <BaseForm.Item noStyle>
              <Auth.SubmitButton
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {t(R.strings.reset_password)}
              </Auth.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </Auth.BaseFormWrapper>
      </Auth.FormBaseWrapper>
    </Auth.FormWrapper>
  )
}

export default ForgotPassword
