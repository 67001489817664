import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import ServicesContainer from 'app/containers/Services'

const ServicesCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.services)}</PageTitle>
      <ServicesContainer />
    </>
  )
}
export const ServicesPage = memo(ServicesCpn, isEqual)
