import React, { useRef, useEffect } from 'react'
import { LayoutMain, LayoutMaster } from '../styles'
import { MainContent } from '../../MainContent'
import { MainHeader } from '../../MainHeader'
import { MobileDashboardPage } from 'app/page/menu/dashboard/mobile-dashboard'
import { MobileFooterPage } from 'app/page/menu/footer/mobile-footer'
import { MobileTeamPage } from 'app/page/menu/team/mobile-team'
import { MobileServicesPage } from 'app/page/menu/services/mobile-services'
import { MobileProjectsPage } from 'app/page/menu/projects/mobile-projects'
import { MobilePartnerPage } from 'app/page/menu/partner/mobile-partner'
import { MobileIntroducePage } from 'app/page/menu/introduce/mobile-introduce'

interface MobileMainLayoutProps {
  isTwoColumnsLayout: boolean
  siderCollapsed: boolean
  toggleSider: () => void
}

export const MobileMainLayout: React.FC<MobileMainLayoutProps> = ({
  isTwoColumnsLayout,
}) => {
  const refDashboard = useRef<HTMLDivElement>(null)
  const refIntroduce = useRef<HTMLDivElement>(null)
  const refServices = useRef<HTMLDivElement>(null)
  const refTeam = useRef<HTMLDivElement>(null)
  const refProjects = useRef<HTMLDivElement>(null)
  const refPartner = useRef<HTMLDivElement>(null)
  const refFooter = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const headerHeight = 60
    const currentPath = window.location.pathname

    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current) {
        const elementPosition =
          ref.current.getBoundingClientRect().top + window.pageYOffset
        const offsetPosition = elementPosition - headerHeight

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }

    switch (currentPath) {
      case '/dashboard':
        scrollToSection(refDashboard)
        break
      case '/introduce':
        scrollToSection(refIntroduce)
        break
      case '/services':
        scrollToSection(refServices)
        break
      case '/team':
        scrollToSection(refTeam)
        break
      case '/projects':
        scrollToSection(refProjects)
        break
      case '/partner':
        scrollToSection(refPartner)
        break
      case '/footer':
        scrollToSection(refFooter)
        break
      default:
        break
    }
  }, [])

  return (
    <LayoutMaster>
      <MainHeader isTwoColumnsLayout={isTwoColumnsLayout} />
      <LayoutMain>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div id="dashboard" ref={refDashboard}>
            <MobileDashboardPage />
          </div>
          <div id="introduce" ref={refIntroduce}>
            <MobileIntroducePage />
          </div>
          <div id="services" ref={refServices}>
            <MobileServicesPage />
          </div>
          <div id="team" ref={refTeam}>
            <MobileTeamPage />
          </div>
          <div id="project" ref={refProjects}>
            <MobileProjectsPage />
          </div>
          <div id="partner" ref={refPartner}>
            <MobilePartnerPage />
          </div>
          <div id="footer" ref={refFooter}>
            <MobileFooterPage />
          </div>
        </MainContent>
      </LayoutMain>
    </LayoutMaster>
  )
}
