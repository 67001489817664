import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as S from './styles'
import { ContentItem, ModuleType, requestGetContentList } from 'app/api/auth'

const PartnerPage: React.FC = () => {
  const [contentList, setContentList] = useState<ContentItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const language = searchParams.get('language') || 'vn'

  useEffect(() => {
    const fetchContentList = async () => {
      try {
        const response = await requestGetContentList(language)
        if (response) {
          const filteredContent = response.filter(
            (item: ContentItem) => item.module === ModuleType.Partner,
          )
          setContentList(filteredContent)
          if (filteredContent.length === 0) {
            setError('No partner details available.')
          }
        } else {
          setError('No content found.')
        }
      } catch (error) {
        setError('Failed to fetch content list.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchContentList()
  }, [language])

  const formatContent = (html: string) => {
    return html
      .replace(/<p class="ql-align-center">/g, '<div class="ql-align-center no-padding">')
      .replace(/<p class="ql-align-left">/g, '<div class="ql-align-left no-padding">')
      .replace(/<p class="ql-align-right">/g, '<div class="ql-align-right no-padding">')
      .replace(/<\/p>/g, '</div>')
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (contentList.length === 0) {
    return <div>No partners available.</div>
  }

  return (
    <S.ScrollWrapper>
      <S.SectionsContainer>
        {contentList.map(section => {
          const imageUrl = section.document?.url || section.imageUrl
          const description = formatContent(section.description || '')

          return (
            <S.BaseSection key={section.id}>
              <S.ContentWrapper>
                <S.TextContent>
                  <S.Title>{section.title}</S.Title>
                  <S.TextCustom dangerouslySetInnerHTML={{ __html: description }} />
                </S.TextContent>
                <S.ImageWrapper>
                  <S.SmallImagesWrapper>
                    <S.SmallImage>
                      <img src={imageUrl} alt="Partner Image" />
                    </S.SmallImage>
                  </S.SmallImagesWrapper>
                </S.ImageWrapper>
              </S.ContentWrapper>
            </S.BaseSection>
          )
        })}
      </S.SectionsContainer>
      <S.SectionSeparator style={{ textAlign: 'center' }}></S.SectionSeparator>
    </S.ScrollWrapper>
  )
}

export default PartnerPage
