import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ContentItem, requestGetContentList } from 'app/api/auth'
import { EDITNEWSLIST_PATH } from 'app/components/router/route-path'
import * as S from './styles'
import { BaseButton } from 'app/components/common/BaseButton'
import R from 'app/assets/R'
import { t } from 'i18next'
import { ModuleType } from 'app/api/auth/model'

const NewsListContainer: React.FC = () => {
  const [contentsList, setContentList] = useState<ContentItem[]>([])
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const [language, setLanguage] = useState<string>(
    searchParams.get('language') || 'vn',
  )

  useEffect(() => {
    let isMounted = true

    const loadContents = async () => {
      try {
        const data = await requestGetContentList(language)
        if (isMounted && Array.isArray(data)) {
          setContentList(data)
        }
      } catch (error) {
        console.error('Failed to load news list:', error)
      }
    }

    loadContents()

    return () => {
      isMounted = false
    }
  }, [location.state?.refresh, language])

  useEffect(() => {
    if (location.state?.updatedNews) {
      const updatedNews = location.state.updatedNews
      const newLanguage = location.state.language || language // Lấy language từ state nếu có

      setLanguage(newLanguage) // Đặt lại ngôn ngữ từ state

      setContentList(prevList =>
        prevList.map(item => (item.id === updatedNews.id ? updatedNews : item)),
      )
    }
  }, [location.state?.updatedNews, location.state?.language])

  const handleEdit = (news: ContentItem) => {
    const currentLanguage =
      new URLSearchParams(location.search).get('language') || 'vn'
    navigate(
      `${EDITNEWSLIST_PATH.replace(
        ':newsId',
        news.id.toString(),
      )}?language=${currentLanguage}`,
      {
        state: { news, language: currentLanguage },
      },
    )
  }

  const avatarUrl = 'https://via.placeholder.com/50'

  const handleSwitchLanguage = (newLanguage: string) => {
    setLanguage(newLanguage)
    navigate(`/admin/newslist?language=${newLanguage}`, {
      state: {
        refresh: true,
        language: newLanguage, // Chuyển ngôn ngữ qua state
      },
    })
  }

  return (
    <S.NewsLists>
      <S.HeaderContainer>
        <h1>{t(R.strings.news_management)}</h1>
        <S.TabContainer>
          <S.TabButton
            active={language === 'vn'}
            onClick={() => handleSwitchLanguage('vn')}
          >
            {t(R.strings.vietnamese)}
          </S.TabButton>
          <S.TabButton
            active={language === 'en'}
            onClick={() => handleSwitchLanguage('en')}
          >
            {t(R.strings.english)}
          </S.TabButton>
        </S.TabContainer>
      </S.HeaderContainer>
      <S.NewsTable>
        <thead>
          <tr>
            <th>{t(R.strings.title)}</th>
            <th>{t(R.strings.task)}</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(ModuleType).map(moduleType => (
            <React.Fragment key={moduleType}>
              {contentsList.length > 0
                ? contentsList
                    .filter(c => c.module === moduleType)
                    .map(
                      news =>
                        news.title && (
                          <tr key={news.id}>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={news.document?.url || avatarUrl}
                                  alt={news.document?.name || ''}
                                />
                                <span>{news.title}</span>
                              </div>
                            </td>
                            <td>
                              <BaseButton onClick={() => handleEdit(news)}>
                                {t(R.strings.edit)}
                              </BaseButton>
                            </td>
                          </tr>
                        ),
                    )
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </S.NewsTable>
    </S.NewsLists>
  )
}

export default NewsListContainer
