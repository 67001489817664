import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import * as S from './styles'
import { BaseButton } from 'app/components/common/BaseButton'
import TextArea from 'antd/es/input/TextArea'

interface Section {
  id: number
  title: string
  description: string
  imageUrl: string
}

interface EditableSectionProps {
  section: Section
  index: number
  onEdit: (title: string, description: string, imageUrl: string) => void
  onDelete: () => void
  isEditing: boolean
}

const EditableSection: React.FC<EditableSectionProps> = ({
  section,
  onEdit,
  onDelete,
  isEditing,
}) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(section.title)
  const [description, setDescription] = useState(section.description)
  const [imageUrl, setImageUrl] = useState(section.imageUrl)

  const handleSave = () => {
    if (!title.trim() && !description.trim() && !imageUrl.trim()) {
      onDelete()
    } else {
      onEdit(title, description, imageUrl)
    }
  }

  useEffect(() => {
    if (title !== section.title || description !== section.description) {
      handleSave()
    }
  }, [title, description])

  useEffect(() => {
    if (imageUrl !== section.imageUrl) {
      handleSave()
    }
  }, [imageUrl])

  const handleImageUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(event.target.value)
  }

  return (
    <S.BaseSection>
      {isEditing ? (
        <>
          <S.EditableContainer>
            <S.InputWrapper>
              <label>{t(R.strings.title)}:</label>
              <TextArea
                value={title}
                onChange={e => setTitle(e.target.value)}
                onBlur={handleSave}
                placeholder={t(R.strings.title)}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <label>{t(R.strings.description)}:</label>
              <TextArea
                value={description}
                onChange={e => setDescription(e.target.value)}
                onBlur={handleSave}
                placeholder={t(R.strings.description)}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <label>{t(R.strings.upload_image)}:</label>
              <input
                type="text"
                value={imageUrl}
                onChange={handleImageUrlChange}
                onBlur={handleSave}
                placeholder={t(R.strings.upload_image)}
              />
            </S.InputWrapper>
          </S.EditableContainer>
          <S.ButtonContainer>
            <BaseButton onClick={onDelete}>{t(R.strings.delete)}</BaseButton>
          </S.ButtonContainer>
        </>
      ) : (
        <>
          <S.TextContent>
            <S.TextCustom
              dangerouslySetInnerHTML={{ __html: section.description }}
              className="ql-editor"
            />
          </S.TextContent>
          <S.ContentImageContainer>
            <S.ImageWrapper>
              {imageUrl && <img src={imageUrl} alt="Content related" />}
            </S.ImageWrapper>
          </S.ContentImageContainer>
        </>
      )}
    </S.BaseSection>
  )
}

export default EditableSection
