
import { WithChildrenProps } from 'parkway-web-common';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const PageTitle: React.FC<WithChildrenProps> = () => {
  return (
    <Helmet>
      <title>Logistic</title>
    </Helmet>
  );
};
