import { memo } from 'react'
import { isEqual } from 'lodash'
import FooterContainer from 'app/containers/Footer/DesktopFooter'

const FooterCpn = () => {
  return (
    <>
      <FooterContainer />
    </>
  )
}
export const FooterPage = memo(FooterCpn, isEqual)
