import { BaseMenu } from 'app/components/common/BaseMenu'
import { BaseRow } from 'app/components/common/BaseRow'
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const Menu = styled(BaseMenu)`
  background-color: midnightblue;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const LeftPoint = styled.div<{ $isSelected?: boolean }>`
  transition: 0.5s ease;
  height: 100%;
`

export const LeftPointSubMenu = styled.div<{ $isSelected?: boolean }>`
  transition: 0.5s ease;
  border-radius: ${BORDER_RADIUS};
`

export const ItemMenuCustomWrapper = styled(BaseRow)<{ $isSelected: boolean }>`
  border-radius: ${BORDER_RADIUS};
  padding: 0 10px;
`

export const LabelCustom = styled.span<{ $isSelected: boolean }>`
  padding: 0.5rem;
  color: white;
`
export const title = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 80%;
  line-height: 1.375rem;

  color: var(--text-main-color);
`
