import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginContainer from 'app/containers/Login';
import { withLoading } from 'app/hocs/with-loading.hoc';
import { NotFound } from '../common/NotFound';
import NotAuth from './not-auth-router';
import {
  ADMIN_PATH,
  AUTH_PATH,
  DASHBOARD_PATH,
  EDITNEWSLIST_PATH,
  ENTER_CODE_PATH,
  FORGOT_PASSWORD_PATH,
  INTRODUCE_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  NEWSLIST_PATH,
  PARTNER_PATH,
  PROJECTS_PATH,
  RESET_PASSWORD_PATH,
  SERVICES_PATH,
  TEAM_PATH,
} from './route-path';
import MainLayout from '../layouts/main/MainLayout';
import { IntroducePage } from 'app/page/menu/introduce/desktop-introduce';
import { ServicesPage } from 'app/page/menu/services/desktop-services';
import { ProjectsPage } from 'app/page/menu/projects/desktop-projects';
import ForgotPassword from 'app/containers/Login/ForgotPassword/ForgotPassword';
import EnterCode from 'app/containers/Login/ForgotPassword/EnterCode/EnterCode';
import ResetPassword from 'app/containers/Login/ForgotPassword/EnterCode/ResetPassword/ResetPassword';
import { NewsListPage } from 'app/page/menu/NewsListPage/desktop-newslistpage';
import AdminLayout from '../layouts/admin/AdminLayout';
import EditNewsPage from 'app/containers/NewsListPage/EditNewsPage';
import TeamPage from 'app/containers/Team';
import PartnerPage from 'app/containers/Partner';
import { DashboardPage } from 'app/page/menu/dashboard/desktop-dashboard';

const Logout = React.lazy(() => import('./logout-router'));
const AuthLayout = React.lazy(() => import('app/components/layouts/AuthLayout'));

const Dashboard = withLoading(DashboardPage);
const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
const Introduce = withLoading(IntroducePage);
const Services = withLoading(ServicesPage);
const Projects = withLoading(ProjectsPage);

export const AppRouter: React.FC = () => {
  const authLayout = (
    <NotAuth>
      <AuthLayoutFallback />
    </NotAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          <Route path={INTRODUCE_PATH} element={<Introduce />} />
          <Route path={SERVICES_PATH} element={<Services />} />
          <Route path={TEAM_PATH} element={<TeamPage />} />
          <Route path={PROJECTS_PATH} element={<Projects />} />
          <Route path={PARTNER_PATH} element={<PartnerPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path={ADMIN_PATH} element={<AdminLayout />}>
        <Route path={NEWSLIST_PATH} element={<NewsListPage />} />
        <Route path={EDITNEWSLIST_PATH} element={<EditNewsPage />} />

        </Route>


        <Route path={AUTH_PATH} element={authLayout}>
          <Route path={LOGIN_PATH} element={<LoginContainer />} />
          <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
          <Route path={ENTER_CODE_PATH} element={<EnterCode />} />
          <Route path={RESET_PASSWORD_PATH} element={<ResetPassword />} />
        </Route>
        <Route path={LOGOUT_PATH} element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};