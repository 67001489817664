import { memo } from 'react'
import { isEqual } from 'lodash'
import NewsListContainer from 'app/containers/NewsListPage'

const NewsListCpn = () => {
  return (
    <>
      <NewsListContainer />
    </>
  )
}

export const NewsListPage = memo(NewsListCpn, isEqual)
