import styled from 'styled-components'

export const ScrollWrapper = styled.div`
  @media (max-width: 768px) {
    .ant-card-body {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
    }
  }
`

export const BaseSection = styled.div`
  @media (max-width: 768px) {
    background-color: var(--background-color);
    padding: 0 1rem;
  }
`

export const HeaderContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }
`

export const TitleWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    width: 100%;
  }
`

export const EditButtonWrapper = styled.div`
  @media (max-width: 768px) {
    flex-shrink: 0;
  }
`

export const ContentImageContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const TextContent = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
    text-align: justify;
  }
`

export const ImageWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      max-height: 20rem;
      object-fit: cover;
      padding: 0 2rem;
    }
  }
`

export const ButtonContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      margin-left: 10px;
    }
  }
`

export const ImagePreview = styled.div`
  @media (max-width: 768px) {
    img {
      width: 100%;
      height: auto;
      max-height: 300px;
      object-fit: cover;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-top: 8px;
    }
  }
`

export const EditableContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`

export const InputWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
      margin-bottom: 5px;
    }

    textarea {
      resize: vertical;
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 10px;
      font-size: 14px;
      width: 100%;
      box-sizing: border-box;
    }

    input[type='file'] {
      margin-top: 8px;
    }
  }
`

export const ButtonsWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
`

export const FileUploadContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

export const SectionsContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    background-color: white;
  }
`

export const SectionSeparator = styled.hr`
  @media (max-width: 768px) {
    border: none;
    border-top: 1px solid #ccc;
    width: 80%;
    margin: 0 2rem;
  }
`

export const Title = styled.div`
  @media (max-width: 768px) {
    font-size: 1.5rem;
    font-weight: bold;
    color: midnightblue;
    text-align: center;
    padding: 1rem 0 0;
  }
`

export const TextCustom = styled.div`
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    padding: 0 1rem 2rem;
    color: black;
  }
`

export const IconTitleWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;

    svg,
    img {
      margin-right: 8px;
    }
  }
`
