import { IUser } from 'app/model/user.model';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface IPayloadNewPassword {
  old_password: string;
  password: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: IUser;
}
export interface ContentItem {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  document: ImageItem;
  module: ModuleType;
  position: number;
}

export interface ImageItem{
  id: number;
  url: string;
  name: string;
  key: string;
}

export interface EditContentItemRequest{
  title: string;
  description: string;
  position: number;
  file: File;
}

export enum ModuleType {
  None = 0,
  Banner = 1,
  AboutUs = 2,
  Service = 3,
  Project = 4,
  Contact = 5,
  Team = 6,
  Partner = 7,
}